@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Silkscreen&display=swap');

*{
    font-family: 'Silkscreen', cursive;
}

.login-page{
    height: 100vh;
    background-color: #20063B;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-col{
    background-color: #33658A;
    border-radius: 5px ;
}

.login-form{
    padding: 40px 20px;
    font-size: 3vh;
    color: black;
}

.error-msg{
    font-size: 2.5vh;
    color: #FF715B;
}

.App{
    height: 100vh;
}

.personal-nav{
    height: 10vh;
    align-items: center;
    background-color: #20063B;
}
.estado-col{
    font-size: 4vh;
}

.estado-alarma-col{
    display: flex;
    height: 90vh;
}

.estado-funcionamiento-col{
    display: flex;
    height: 90vh;
}

.estado-funcionamiento-col:hover , .activo:hover{
    cursor: pointer;
}

.estado-text{
    margin: auto;
}

.activo{
    background-color: #4C956C;
}

.desactivado{
    background-color: #FF715B;
}

@media only screen and (max-width: 768px) {
    .estado-funcionamiento-col{
        height: 45vh;
    }
    .estado-alarma-col{
        height: 45vh;
    }
}